<template>
    <div class="main">
        <!-- &lt;!&ndash;        百度地图bmap承接div&ndash;&gt; -->
        <div class="bm-view" id="allmap"></div>
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {getStore,setStore,getLocalStore} from "@/util/store";
//提前全局声明BMap，map，方便后续方法使用BMap.XX方法
let bmapPromise;
let BMap = {};
// let BMapLib = {};
let map = {};
export default {
    name: "index",
    data() {
        return {
            proname: getStore('thisProject').proName,
            //中心点图形
            proImg: require('../../assets/img/map_icon_nowlocation.png'),
            //右下角快捷搜索是否显示
            //中心点
            center: {
                lng: null,
                lat: null
            },
            //当前缩放比例
            ZoomNum: 16,
            //初始化缩放比例
            zoom: 16,
            //当前标点图形
            dotIcon: require('../../assets/img/map_icon.png'),
            //地图实例
            mapObj : '',
        }
    },
    computed: {},
    created() {
        //获取地图中心、异步加载bmapjs文件
        this.getMapCenter();
        this.loadBmapJS();
    },

    mounted() {
        setTimeout(() => {
            //初始化地图
            this.initMap();
        }, 1000)
    },
   
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'mapAround',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //加载百度地图api
        loadBmapJS() {
            if (window.BMap) {
                return Promise.resolve();
            }
            if (bmapPromise) {
                return bmapPromise;
            }
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = 'https://api.map.baidu.com/api?v=3.0&ak=jMnfAv2MlXEUmzzDjjGvlmXrpj0TP66a&callback=initMap'
         
            document.body.appendChild(script);
            bmapPromise = new Promise((resolve, reject) => {
                script.onload = () => {
                    resolve();
                    // this.loadBmapLibJS();
                };
                script.onerror = error => {
                    reject(error)
                }
            })

        },
        loadBmapLibJS() {
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "http://api.map.baidu.com/library/RichMarker/1.2/src/RichMarker_min.js";
            // script.onload = () => {
            //     this.initMap();
            // }
             bmapPromise = new Promise((resolve, reject) => {
                script.onload = () => {
                    resolve();
                    this.initMap();
                };
                script.onerror = error => {
                    reject(error)
                }
            })
            document.body.appendChild(script);
        },
        //初始化地图
        initMap() {
            BMap = window.BMap;
            map = new BMap.Map('allmap');
            this.mapObj = map;
            //初始化地图中心、层级
            map.centerAndZoom(new BMap.Point(this.center.lng, this.center.lat), this.zoom);
            //允许地图鼠标滚轮缩放层级
            map.enableScrollWheelZoom(true);
            //定义右上角百度地图工具栏 平移和缩放按钮
            var top_right_navigation = new BMap.NavigationControl({
                anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
                type: BMAP_NAVIGATION_CONTROL_SMALL
            });
            map.addControl(top_right_navigation);
            map.addControl(new BMap.MapTypeControl({
                mapTypes:[
                    BMAP_NORMAL_MAP,
                    BMAP_HYBRID_MAP
                ],
                anchor: BMAP_ANCHOR_TOP_RIGHT,
            }));	
            //百度全景地图
            var stCtrl = new BMap.PanoramaControl({
                anchor: BMAP_ANCHOR_TOP_RIGHT,
                type: BMAP_NAVIGATION_CONTROL_SMALL
            });  
            stCtrl.setOffset(new BMap.Size(20, 40));  
            map.addControl(stCtrl);
            
            //监听地图缩放，赋值到ZoomNum
            map.addEventListener("zoomend", (e) => {
                this.ZoomNum = e.target.getZoom()
            });
            // 创建点
            let myIcon = new BMap.Icon(this.proImg, new BMap.Size(32, 32));
            let mpoint = new BMap.Point(this.center.lng, this.center.lat);
            let marker = new BMap.Marker(mpoint, {icon: myIcon});
            map.addOverlay(marker);    //增加点
            marker.addEventListener("click", () => {

            });
            //中心点标签
            let cont = "<span class='markername' style='background-color:rgba(255,255,255,.8);border:1px solid #498AFF'>" + this.proname + "</span><span id='arrows'></span>";
            let label = new BMap.Label(cont, {offset: new BMap.Size(0, -15)});
            marker.setLabel(label);
            marker.disableMassClear(); //不被clearOverlay清除
        },
        //取得地图中心坐标
        getMapCenter() {
            this.center.lng = getStore('thisProject').longitude;
            this.center.lat = getStore('thisProject').latitude;
        },
  
    }
}
</script>

<style scoped lang="less">

.main {
    position: relative;
    width: 100% !important;
    height: 100%;

    .bm-view {
        position: relative;
        width: 100%;
        height: 100%;
    }

    /deep/ .BMapLabel {
        .markername {
            padding: 5px;
            display: block;
            height: 40px;
            line-height: 40px;
            width: 120px;
            text-align: center;
            border: 1px solid #cccccc;
            background-color: #fff;
            border-radius: 3px;
            font-weight: bold;
            font-size: 14px;
            box-shadow: 0 3px 14px rgba(0, 0, 100, .6);
            position: absolute;
            top: -41px;
            left: -47px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        #arrows {
            background: url(../../assets/img/marksArrow.png) no-repeat center center;
            height: 11px !important;
            position: absolute;
            width: 30px;
            top: 10px;
            left: 0;
        }
    }
  


  
}

</style>
